<template>
    <div class="accountform">
      <img src="../../assets/image/nominatorBg.png" class="accountBack">
      <div class="contentBack">
          <el-form :model="form" ref="form" :rules="formRules">
              <div class="row">
                  <div class="rowItem">
                    <el-form-item label="Your Name" prop="user_name">
                          <el-input style="width:500px" v-model="form.user_name"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Name of Your School" prop="school">
                          <el-input style="width:500px" v-model="form.school"></el-input>
                      </el-form-item>
                  </div>

                  


              </div>
              <div class="row">

                <div class="rowItem" >
                      <el-form-item label="Your Email Address" prop="user_email">
                          <el-input style="width:500px" v-model="form.user_email"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Your Phone Number" prop="user_phone">
                          <el-input style="width:500px" v-model="form.user_phone"></el-input>
                      </el-form-item>
                  </div>

              </div>


              <div class="row">
                  <el-form-item label="Where do you learn about EAE" prop="where">
                      <div style="display: inline-block;width:100%">
                          <el-checkbox-group v-model="form.where">
                              <div style="width:500px"><el-checkbox label="Website Search" /></div>
                              <div style="width:500px"><el-checkbox label="Social Media" /></div>
                              <div style="width:500px"><el-checkbox label="Friend's Recommendation" /></div>
                              <div style="width:500px"><el-checkbox label="Application Counselor" /></div>
                              <div style="width:500px"><el-checkbox label="Others"/></div>
                          </el-checkbox-group>
                      </div>
                  </el-form-item>
              </div>
              <div class="row">
                   <el-form-item label="Which category do you plan to apply?" prop="selectCategory">
                      <div style="display: inline-block;width:100%">
                        <el-select
                          v-model="form.selectCategory"
                          placeholder="Select category"
                          @change="doSearch"
                          style="width: 500px"
                        >
                          <el-option
                            v-for="(category, index) in categories"
                            :key="index"
                            :label="category.name"
                            :value="category.name"
                          ></el-option>
                        </el-select>
                      </div>
                  </el-form-item>
              </div>
              <div class="row">
                  <div class="rowItem">
                     <el-form-item label="Name of Your Teacher" prop="contact_name">
                          <el-input style="width:500px" v-model="form.contact_name"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Email of Your Teacher" prop="contact_email">
                          <el-input style="width:500px" v-model="form.contact_email"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <!-- <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Registrant’s Mobile" prop="regMobile">
                          <el-input style="width:500px" v-model="form.regMobile"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Registrant’s IM (Please also specify the IM platform)" prop="regWechat">
                          <el-input style="width:500px" v-model="form.regWechat"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Registrant’s Email Address" prop="regEmail">
                          <el-input style="width:500px" v-model="form.regEmail"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Registrant’s Title" prop="regTitle">
                          <el-input style="width:500px" v-model="form.regTitle"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Are you also the program adviser?" prop="adviserStatus">
                          <el-input style="width:500px" v-model="form.adviserStatus"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row" style="font-size: 16px;color: #333333;font-weight: bold;">Program Adviser</div>
              <div class="row" style="color:#666666">If you are not the adviser，please provide the adviser’s information in the following form.</div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Name">
                          <el-input style="width:500px" v-model="form.adviserName"></el-input>
                      </el-form-item>
                  </div>
                  <div class="rowItem" >
                      <el-form-item label="Title">
                          <el-input style="width:500px" v-model="form.adviserTitle"></el-input>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="rowItem">
                      <el-form-item label="Email">
                          <el-input style="width:500px" v-model="form.adviserEmail"></el-input>
                      </el-form-item>
                  </div>
              </div> -->
              <div class="row" style="margin-bottom:50px">
                  <span class="submit" @click="submit">Submit</span>
              </div>
          </el-form>
      </div>
    </div>
  </template>

<script>
import { saveNominatorForm,getTypes } from '../../api/eae'

import '../../assets/common/common.css'

export default {
  data () {
    return {
        categories:[],
        form: {
            user_name: '',
            school: '',
            user_email: [],
            user_phone: [],
            where: [],
            selectCategory: '',
            contact_name: '',
            contact_email: '',
        },
        formRules: {
            user_name: [
            { required: true, message: 'Please input', trigger: 'blur' }
            ],
            school: [
            { required: true, message: 'Please input', trigger: 'blur' }
            ],
            user_email: [
            { required: true, message: 'Please input', trigger: 'blur' }
            ],
            where: [
            { required: true, message: 'Please select', trigger: 'change' }
            ],
            contact_name: [
            { required: true, message: 'Please input', trigger: 'blur' }
            ],
            contact_email: [
            { required: true, message: 'Please input', trigger: 'blur' }
            ],
            selectCategory: [
            { required: true, message: 'Please select', trigger: 'change' }
            ]
        }
    }
  },
  mounted() {
    this.requestAllCategories();
  },
  methods: {
    submit () {
      this.$refs.form.validate((val) => {
        if (val) {
          saveNominatorForm(this.form).then(res => {
            if (res.data) {
              this.$message.success('Added Successfully')
            }
          })
        }
      })
    },
    
    requestAllCategories() {
      getTypes().then((res) => {
        let result = [
            ...res.data.data.filter((item) => item.name !== "Others"),
            ...res.data.data.filter((item) => item.name === "Others"),
          ];
        this.categories = result
      });
    },
  }
}
</script>

  <style scoped lang="scss">
  .accountform{
      width: 100%;
      font-family: "DDINAlternateRegular";
      overflow: hidden;
      .accountBack{
          width: 100%;
          display: block;
      }
      .contentBack{
          background: url(../../assets/image/accountBack.png);
          background-position: 0 0;
          background-size: 100% 100%;
          overflow: hidden;
          padding-top: 60px;
      }
      .row{
          width: 1160px;
          margin: 0 auto;
          justify-content: space-between;
          margin-bottom: 20px;
          display: flex;
          .rowItem{

          }
      }
      .el-checkbox-group{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
      }
      .el-radio-group{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
      }
      .submit{
          padding: 10px 70px;
          background: #F55443;
          color: #fff;
          cursor: pointer;
      }
  }
  </style>
  <style lang="scss">
  .accountform{
      .el-form-item__label{
          font-size: 16px;
          color: #333333;
          font-weight: bold;
      }
      .el-checkbox__label{
          font-size: 16px;
          color: #666666;
      }
      .el-radio__label{
          font-size: 16px;
          color: #666666;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
          background-color: #F55443;
          border-color: #F55443;
      }
      .el-checkbox__input.is-checked+.el-checkbox__label{
          color: #F55443;
      }
      .el-radio__input.is-checked .el-radio__inner{
          background-color: #F55443;
          border-color: #F55443;
      }
      .el-radio__input.is-checked+.el-radio__label{
          color: #F55443;
      }
  }
  </style>
